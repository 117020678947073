.highlights {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	padding-top: var(--wpl-spacing-10);
	column-gap: var(--column-gap);
	row-gap: var(--row-gap);

	@media (--small) {
		--column-gap: var(--wpl-spacing-03);
		--row-gap: var(--wpl-spacing-06);
	}

	@media (--medium-up) {
		--column-gap: var(--wpl-spacing-07);
		--row-gap: var(--wpl-spacing-10);

		padding-top: 0;
	}

	@media (--large) {
		--row-gap: var(--wpl-spacing-14);
		--column-gap: var(--wpl-spacing-14);
	}
}

.highlight {
	display: flex;
	flex-direction: column;
	gap: var(--wpl-spacing-03);
}

.iconWrapper {
	height: 48px;
	width: 48px;
	background-color: var(--brand-secondary);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.heading {
	composes: g-type-display-5 from global;
	margin-top: 18px;
}
