.slides {
	display: flex;
	justify-content: center;
	margin: 64px 0;
}

.iframe {
	border: 1px solid #ccc;
	margin-bottom: 4px;
	max-width: 100%;
}
