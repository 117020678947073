.container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: fit-content;
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
}

.thumbnail {
	border-radius: 8px;
	position: relative;
	width: 144px;
	height: 95px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.trigger {
	grid-area: 1 / 1;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.playIconWrapper {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	opacity: 0.7;

	& span {
		margin-left: 3px;
	}
}
