.peopleBlock {
	display: grid;
	grid-gap: 32px;
	list-style: none;
	margin: 0;
	margin-bottom: 32px;
	padding: 0;
	width: 100%;

	@media (--medium-up) {
		grid-template-columns: 1fr 1fr;
	}
}
