.videoBlock {
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
	margin-top: 32px;
	width: 100%;
}

.videoContainer {
	height: 220px;
	max-width: 840px;
	width: 100%;
	@media (min-width: 560px) {
		height: 320px;
	}
	@media (--medium-up) {
		height: 420px;
	}
}
