.root {
	overflow-x: scroll;

	& table {
		border-collapse: collapse;
		border-spacing: 0;
		border-top: 1px solid var(--gray-5);
		max-width: 100%;
		width: 100%;

		/* Rows */
		& tbody {
			& tr {
				border-top: 1px solid var(--gray-5);

				&:nth-of-type(odd) {
					background-color: #f9f9f9;
				}
			}
		}

		/* Cells */
		& td,
		& th {
			line-height: 1.375;
			padding: 15px 25px;
			vertical-align: middle;

			&:first-child {
				display: flex;
			}

			&:not(:first-child) {
				text-align: center;
				width: 20%;
			}

			& > span {
				height: 24px;
				margin-left: 15px;
			}

			& > img {
				height: 24px;
				width: 24px;
			}
		}
	}
}

.tabSelect {
	display: flex;
	text-align: center;
	text-transform: uppercase;
}

.tab {
	background: var(--white);
	cursor: pointer;
	padding: 11px 0;
	transition: all 0.25s ease;
	width: 175px;

	&.active {
		background: var(--brand);
		color: var(--white);
	}
}
