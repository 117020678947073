.root {
	position: relative;

	@media (--medium-up) {
		height: 150vh;
	}
}

.container {
	composes: g-grid-container from global;

	@media (--medium-up) {
		position: sticky;
		top: var(--wpl-spacing-15);
	}
}

.media {
	display: grid;
	place-items: center;
	row-gap: var(--wpl-spacing-06);

	& > * {
		@media (--medium-up) {
			grid-area: 1 / 1;
		}
	}
}

.image {
	border-radius: 8px;
}

.logo {
	position: relative;
	height: 24px;

	@media (--medium-up) {
		height: 40px;
	}
}

.content {
	--margin-top: var(--wpl-spacing-06);

	position: relative;
	text-align: center;
	margin-top: var(--margin-top);

	@media (--medium-up) {
		--margin-top: -110px;
	}

	@media (--large) {
		--margin-top: -130px;
	}
}

.heading {
	margin: 0;
	font-size: 40px;
	line-height: 50px;
	text-align: center;

	@media (--medium-up) {
		font-size: 60px;
		line-height: 70px;
		padding: 0 15%;
	}

	@media (--large) {
		font-size: 78px;
		line-height: 90px;
	}
}
