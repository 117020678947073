.root {
	color: var(--wpl-neutral-0);
}

.content {
	background-image: linear-gradient(
		to bottom,
		var(--wpl-neutral-0) 88px,
		var(--wpl-neutral-900) 88px
	);
	width: 100%;
	padding-bottom: var(--wpl-spacing-13);

	@media (--medium-up) {
		padding-bottom: var(--wpl-spacing-15);
	}
}

.inner {
	composes: g-grid-container from global;
}

.statsWrapper {
	display: grid;
	padding: var(--wpl-spacing-10) 0 var(--wpl-spacing-10);
	row-gap: var(--wpl-spacing-06);

	@media (--medium-up) {
		column-gap: 5vw;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		padding: var(--wpl-spacing-15) 0 var(--wpl-spacing-15);
	}

	@media (--large) {
		column-gap: 140px;
	}
}

.statItem {
	text-align: center;
	padding-top: 0;
	border-top: 0;

	@media (--medium-up) {
		text-align: left;
		padding-top: var(--wpl-spacing-07);
		border-top: 1px solid var(--wpl-neutral-700);
	}
}
