.root {
	background: var(--wpl-neutral-900);
}

.inner {
	composes: g-grid-container from global;
	display: flex;
	justify-content: center;
	padding-top: var(--hdsplus-spacing-18);
	padding-bottom: var(--wpl-spacing-15);
}

.content {
	max-width: 650px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.ctaLinks {
	display: flex;
	gap: var(--wpl-spacing-05);
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	list-style: none;
	margin: 0;
	animation-fill-mode: both;
}

.imageWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow: hidden;
}
