.audioBlock {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.embed {
	display: grid;
	grid-gap: 32px;
	margin-bottom: 32px;
}

.providerLinks {
	display: flex;
	flex-wrap: wrap;
}
