.root {
	--transition-time: 0.25s;

	composes: g-grid from global;
	composes: g-grid-container from global;
	padding-top: var(--wpl-spacing-08);
	padding-bottom: var(--wpl-spacing-10);
	gap: var(--wpl-spacing-05);

	@media (--large) {
		padding-top: var(--wpl-spacing-15);
		padding-bottom: var(--wpl-spacing-15);
	}
}

.cardViewer {
	grid-column: 1/ -1;
	composes: g-grid from global;
	gap: var(--wpl-spacing-06);

	@media (--medium-up) {
		gap: var(--wpl-spacing-07);
	}

	@media (--large) {
		gap: var(--wpl-spacing-09);
	}
}

.items {
	list-style: none;
	padding: 0;
	margin: 0;
	grid-column: 1 / -1;

	@media (--large) {
		grid-column: 1 / 7;
	}
}

.item {
	display: grid;
	grid-template-columns: min-content 1fr;
	align-items: flex-start;
	cursor: pointer;
	gap: var(--wpl-spacing-02);
	background-color: transparent;
	border: none;
	width: 100%;
	padding: var(--wpl-spacing-02) 0;
	border-bottom: 1px solid var(--gray-5);

	&.isLastItem {
		border-bottom: none;
	}

	@media (--medium-up) {
		padding: 20px 0;
	}

	@media (--large) {
		gap: var(--wpl-spacing-05);
	}
}

.iconWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--wpl-spacing-05);
	height: var(--wpl-spacing-05);
	background-color: rgb(255, 255, 255);
	border-radius: 4px;
	opacity: 0.2;
	transition: all var(--transition-time);

	/* This is a hacky way of ensuring that the icon lines up vertically with the adjacent `itemText` on mobile / tablet */
	margin-top: 4px;

	@media (--large) {
		width: var(--wpl-spacing-07);
		height: var(--wpl-spacing-07);
		margin-top: 0;
	}

	&.isActive {
		background-color: var(--brand-secondary);
		opacity: 1;
	}
}

.itemText {
	margin: 0;
	composes: g-type-display-4 from global;
	text-align: left;
	color: var(--gray-4);
	transition: color var(--transition-time);

	&.isActive {
		color: black;
	}
}

.cards {
	grid-column: 1 / -1;
	background-color: var(--brand-secondary);
	border-radius: var(--wpl-spacing-01);
	display: grid;
	overflow: hidden;

	@media (--large) {
		grid-column: 7 / 13;
	}
}

.card {
	position: relative;
	padding: var(--wpl-spacing-06) var(--wpl-spacing-04);
	grid-area: 1 / -1;

	@media (--large) {
		padding: var(--wpl-spacing-09) var(--wpl-spacing-09) var(--wpl-spacing-10);
	}
}

.cardHeading {
	composes: g-type-display-4 from global;
	margin: 0 0 var(--wpl-spacing-04);
}

.cardDescription {
	composes: g-type-body-large from global;
	margin: 0;

	& a {
		color: inherit;
		text-decoration: underline;
		text-underline-offset: 3px;
	}

}

.videoButton {
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
	margin-top: var(--wpl-spacing-06);
}

.videoPreview {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	width: 144px;

	& img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
	}
}

.playIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.videoLabel {
	composes: g-type-label from global;
	margin-top: var(--wpl-spacing-01);
}