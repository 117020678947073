.root {
	margin: 0 auto;
	background: var(--black);
}

.bgBounding {
	position: relative;
	composes: g-grid-container from global;
	max-width: 1600px;
	padding: 0;
}

.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
	grid-template-rows: min-content min-content;
	padding-top: var(--wpl-spacing-12);

	&.hasPadding {
		@media (--small) {
			padding-bottom: var(--wpl-spacing-12);
		}
	}

	@media (--medium-up) {
		padding-top: 132px;
		padding-bottom: 150px;
	}
}

.content {
	grid-column: 1 / -1;
	z-index: 1;

	@media (--medium) {
		grid-column: 1 / 7;
		grid-row: 1 / 1;
	}

	@media (--large) {
		grid-column: 1 / 6;
	}
}

.video {
	position: relative;
	margin-bottom: var(--wpl-spacing-06);
	grid-column: 1 / -1;
	align-self: center;
	border: 1px solid var(--wpl-neutral-700);
	border-radius: var(--wpl-spacing-01);

	@media (--medium-up) {
		margin-bottom: 0;
		grid-column: 7 / -1;
		grid-row: 1 / 1;
		z-index: 10;
	}
}

.desktopMedia {
	overflow: hidden;
	grid-column: 1 / -1;
	width: 100%;
	right: 0;
	left: auto;
	display: none;
	position: absolute;
	top: 0;
	height: 100%;

	& img {
		object-position: top right;
		object-fit: contain;
	}

	@media (--medium) {
		max-width: 90%;
		margin-left: 10%;
	}

	@media (--medium-up) {
		display: block;
	}
}

.smallMedia {
	display: flex;

	@media (--medium-up) {
		display: none;
	}

	& img {
		max-width: 100%;
		height: auto;
	}
}

.title {
	color: var(--white);
	composes: g-type-display-1 from global;
	margin: 0;
	padding-top: 32px;
}

.description {
	color: var(--gray-4);
	composes: g-type-body-large from global;
	margin-bottom: 50px;
	margin-top: 28px;
}

.ctas {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 27px;
	grid-column: 1 / -1;
	padding: 0;
	position: relative;

	@media (--medium-up) {
		grid-column: 1 / 7;
	}
}
