.person {
	display: flex;
	flex-direction: column;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
}

.personPhotoRow {
	margin-bottom: 16px;
	width: 100%;
}

.personPhotoFrame {
	height: 108px;
	position: relative;
	width: 108px;
}

.personDetailsRow {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.name {
	composes: g-type-body-small-strong from global;
}

.title {
	color: var(--gray-2);
	composes: g-type-body-small from global;
}

.personInitials {
	align-items: center;
	background-color: var(--gray-2);
	color: white;
	display: flex;
	height: 100px;
	justify-content: center;
	margin-right: 24px;
	width: 100px;
}

.personInitialsText {
	font-size: 40px;
}

.socialLinks {
	display: flex;
	justify-content: flex-start;
	list-style: none;
	margin: 0;
	margin-top: 8px;
	padding: 0;
	& li {
		margin: 0;
		margin-right: 16px;
		padding: 0;
		& a {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
		}
	}
}

.socialIcon {
	width: 24px;
}
