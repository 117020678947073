.root {
	width: min(100% - 24px, 1800px);
	background: var(--wpl-neutral-50);
	border-radius: 8px;
	padding-top: var(--wpl-spacing-13);
	padding-bottom: var(--wpl-spacing-13);
	margin: 0 auto;
}

.cards {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-06);
}

.card {
	position: relative;
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	background-color: var(--wpl-neutral-0);
	border-radius: 8px;
	overflow: hidden;
	justify-content: space-between;

	@media (--medium-up) {
		grid-column: span 6;
	}

	&::after {
		content: '';
		background-image: var(--brand-gradient);
		position: absolute;
		right: -100px;
		bottom: -100px;
		opacity: 0.7;
		filter: blur(100px);
		width: 658px;
		height: 198px;
	}
}

.textContent {
	padding: var(--wpl-spacing-08);
	padding-bottom: var(--wpl-spacing-14);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
}

.imageWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	border-radius: 8px;
	z-index: 1;
}

.image {
	height: auto;
	max-width: 76%;
}
