.root {
	composes: g-grid from global;
}

.highlightsWrapper {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}
}

.contentWrapper {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / span 4;
	}
}

.linkWrapper {
	padding-top: var(--wpl-spacing-03);

	@media (--medium-up) {
		padding-top: var(--wpl-spacing-06);
	}
}

.featuredProducts {
	display: flex;
	gap: var(--wpl-spacing-05);
}
