.quoteFigure {
	margin: 0;
}

.blockquote {
	margin: 0;
	padding: 0;
}

.description {
	margin-top: var(--hdsplus-spacing-07);
	color: var(--wpl-neutral-50);
	composes: g-type-display-4 from global;
	font-weight: var(--font-weight-reg);

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	& a {
		text-decoration: underline;
	}
}