.root {
	composes: g-grid-container from global;
	padding-top: var(--wpl-spacing-09);
	padding-bottom: var(--wpl-spacing-09);
	background: white;

	@media (--medium-up) {
		padding-top: var(--wpl-spacing-15);
		padding-bottom: var(--wpl-spacing-15);
	}
}

.features {
	list-style: none;
	display: grid;
	padding: 0;
	margin: 0;
	row-gap: var(--wpl-spacing-09);

	@media (--medium-up) {
		row-gap: var(--wpl-spacing-15);
	}
}

.featureItem {
	grid-column: 1 / -1;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-05);
	align-items: center;

	--text-grid-column: 1 / 6;
	--image-grid-column: 7 / -1;

	&:nth-child(even) {
		--text-grid-column: 8 / -1;
		--image-grid-column: 1 / 7;
	}

	@media (--medium-up) {
		grid-template-rows: 1fr;
	}
}

.textContent {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: var(--text-grid-column);
	}
}

.imageWrapper {
	display: flex;
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: var(--image-grid-column);
		grid-row: 1;
	}
}

.image {
	max-width: 100%;
	height: auto;
}
