.figure {
	margin: 0;
	width: 100%;
}

.caption {
	composes: g-type-body-small from global;
	color: var(--wpl-neutral-500);
	text-align: center;
	font-size: 14px;
}
